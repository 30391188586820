<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 점검기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="점검기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="PWC_PRE_WORK_CHECK_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="pwcPreWorkCheckStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.pwcPreWorkCheckStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      title="작업 전 안전점검"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addTask" />
          <!-- 검색 -->
          <c-btn label="LBL0000209" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'preWorkCheck',
  data() {
    return {
      searchParam: {
        plantCd: null,
        period: [],
        pwcPreWorkCheckStepCd: null,
      },
      grid: {
        columns: [
          {
            // 사업장
            name: 'plantName',
            field: 'plantName',
            label: 'LBL0000200',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 점검명
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            // 진행단계
            name: 'pwcPreWorkCheckStepName',
            field: 'pwcPreWorkCheckStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 점검기간
            name: 'period',
            field: 'period',
            label: '점검기간',
            align: 'center',
            sortable: true,
            style: 'width:150px',
          },
          {
            // 관리감독자
            name: 'managerName',
            field: 'managerName',
            label: '관리감독자',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 근로자
            name: 'workerNames',
            field: 'workerNames',
            label: '근로자',
            align: 'left',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.pwc.check.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addTask() {
      // 점검등록
      this.linkClick()
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '작업 전 안전점검'; // 작업 전 안전점검
      this.popupOptions.param = {
        sopPreWorkCheckId: row ? row.sopPreWorkCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./preWorkCheckDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
